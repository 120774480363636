import { Component, OnInit } from '@angular/core';
import { NgForm, FormControl, FormBuilder, Validators } from '@angular/forms';
import { UsuarioModel } from 'src/app/models/usuario.model/usuario.model';
import { ServicioDenunciaService } from '../../services/servicio-denuncia.service';
import { Router } from '@angular/router';
import * as cryptojs from 'crypto-js';
import { GLOBAL } from 'src/app/services/global';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public erroneo = false;
  public loading = false;

  public idGenerado;

  public login = this.fb.group({
    user: ['', Validators.required],
    password: ['', Validators.required]
  });

  constructor(private fb: FormBuilder, private servicioDenuncia: ServicioDenunciaService,
              private router: Router, private toast: ToastrService) {
                // console.log(cryptojs.SHA256(`loginAdd${GLOBAL.PASSWD_SEED}${GLOBAL.PASSWD_SEED}loginAdd`).toString());
              }

  ngOnInit(): void {
    // this.servicioDenuncia.prueba().subscribe(res => console.log(res));
    // setTimeout(() => {
    //   this.toast.warning('Token caducado, por favor, inicie sesión para crear uno nuevo', '', { "positionClass": "toast-bottom-right" });
    // }, 1000);
  }

  doLogin(){

    this.loading = true;
    this.erroneo = false;

    let user = this.login.get('user').value;
    let password = this.login.get('password').value;

    if (this.login.valid){
      this.servicioDenuncia.login(user, password).subscribe( (res: any) => {

        if (res.Result == 'OK'){
          this.loading = false;
          localStorage.setItem('userTokenDenuncias', res.user[0].token);
          this.router.navigateByUrl('/home');
        } else {
          this.loading = false;
          this.erroneo = true;
        }
      }, error =>{
        this.loading = false;
        console.log(error);
      })
    } else {
      this.loading = false;
      window.alert('Por favor, introduzca el usuario y contraseña');
    }

  }

  // prueba() {
  //   this.servicioDenuncia.prueba().subscribe(res => console.log(res));
  // }

}
